import React from "react";
import classNames from "classnames";
import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

import ModalText from "components/ModalText";

export default function AgendaInIframe() {
  useWindowScrollTop();
  const { agenda} = useCopy();
 

  return (
    <main>
      <div className="container">
        <h1 className="static_pageTitle text-center" dangerouslySetInnerHTML={{ __html: agenda.title }} />
        

        <div className="static_page_contentHolder"> 
          {agenda.description.map((desc, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: desc }} />;
          })}
        </div>
      </div>
      <div className="container" style={{height: '100vh'}}>
          <iframe src="https://console.personatech.com/iframe/event-agenda?eid=2080698d-208d-4d78-8506-76aa61f83421&theme=GREEN" width="100%" height="100%"></iframe>
      </div>
    </main>
  );
}


